/* eslint-disable @typescript-eslint/no-unused-vars */
import {isString, validDay, validMonth, validYear, validDateOfBirth, validDate, validCustomYear} from '@nib/validation';

const validateDateOfBirthFields = (
  values,
  errors,
  yearField,
  monthField,
  dayField,
  dateOfBirthField,
  maxAge = 100,
  minAge,
  funnel = 'ARHI',
  emptyMessage = 'Please enter your date of birth.',
  isDependant = false
) => {
  errors[dayField] = validDay(values[dayField]);
  errors[monthField] = validMonth(values[monthField]);
  errors[yearField] = validYear(values[yearField], maxAge, minAge, funnel, isDependant);

  if (values[dateOfBirthField] && validDateOfBirth(values[dateOfBirthField] || '', maxAge, minAge, funnel, isDependant)) {
    errors[dateOfBirthField] = validDateOfBirth(values[dateOfBirthField] || '', maxAge, minAge, funnel, isDependant);
  }

  if (isString(errors[yearField])) {
    errors[dateOfBirthField] = errors[yearField];
  }

  if (isString(errors[monthField])) {
    errors[dateOfBirthField] = errors[monthField];
  }

  if (isString(errors[dayField])) {
    errors[dateOfBirthField] = errors[dayField];
  }

  return errors;
};

export const validateDateFields = (values, errors, yearField, monthField, dayField, dateField) => {
  errors[dayField] = validDay(values[dayField]);
  errors[monthField] = validMonth(values[monthField]);
  errors[yearField] = validCustomYear(values[yearField]);

  if (values[dateField] && validDate(values[dateField])) {
    errors[dateField] = validDate(values[dateField]);
  }

  if (isString(errors[yearField])) {
    errors[dateField] = errors[yearField];
  }

  if (isString(errors[monthField])) {
    errors[dateField] = errors[monthField];
  }

  if (isString(errors[dayField])) {
    errors[dateField] = errors[dayField];
  }

  return errors;
};

export default validateDateOfBirthFields;
