import React from 'react';
import {Stack, Columns} from '@nib/layout';
import Modal from '@nib-components/modal';
import {SecondaryButton} from '@nib-components/button';
import {PdfSystemIcon, HyperlinkSystemIcon} from '@nib/icons';
import {isNibBrand} from '../../../services/utils';
import {useBrandSpecificLinksStatic, usePrivacyPolicyModalStatic} from '../../../hooks';

const icons = {
  pdf: PdfSystemIcon,
  link: HyperlinkSystemIcon
};

const PrivacyPolicyModal = ({visible, onClose}) => {
  const {privacyPolicyNIB, privacyPolicyExternal} = useBrandSpecificLinksStatic();
  const {description, brokerPolicyLabel, brokerUrlType} = usePrivacyPolicyModalStatic();

  return (
    <Modal visible={visible} onClose={onClose} title={'Privacy Policy'}>
      <Stack space={6}>
        <span>{description}</span>

        <Columns space={4}>
          <Stack space={3}>
            <SecondaryButton href={`${!isNibBrand() ? 'https://www.nib.com.au' : ''}${privacyPolicyNIB}`} target="_blank" rel="noopener noreferrer" icon={PdfSystemIcon} fullWidth={true}>
              nib Privacy Policy{' '}
            </SecondaryButton>
            <SecondaryButton href={privacyPolicyExternal} target="_blank" rel="noopener noreferrer" icon={icons[brokerUrlType]} fullWidth={true}>
              {brokerPolicyLabel}
            </SecondaryButton>
          </Stack>
        </Columns>
      </Stack>
    </Modal>
  );
};

export default PrivacyPolicyModal;
