import React from 'react';
import {withVariants} from '../WithVariants';
import Link from '@nib-components/link';
import PrivacyPolicyModal from '../Modals/PrivacyPolicyModal';
import {useToggle, useBrandSpecificLinksStatic} from '../../hooks';

const privacyPolicyLinkCopy = 'privacy policy';

const LinkNib = () => {
  const {privacyPolicyNIB} = useBrandSpecificLinksStatic();
  return (
    <Link href={privacyPolicyNIB} tabIndex="0" color="loud" id="privacy-policy" target="_blank" rel="noopener noreferrer">
      {' '}
      {privacyPolicyLinkCopy}
    </Link>
  );
};

const LinkGu = () => {
  const {privacyPolicyGuHealth} = useBrandSpecificLinksStatic();
  return (
    <Link href={privacyPolicyGuHealth} tabIndex="0" color="loud" id="privacy-policy" target="_blank" rel="noopener noreferrer">
      {' '}
      {privacyPolicyLinkCopy}
    </Link>
  );
};

const LinkWithModal = () => {
  const {state: showPrivacyPolicyModal, toggle} = useToggle();
  return (
    <>
      <PrivacyPolicyModal visible={showPrivacyPolicyModal} onClose={toggle} />
      <Link onClick={toggle} tabIndex="0" component="button">
        {privacyPolicyLinkCopy}
      </Link>
    </>
  );
};

const variants: any = {
  nib: LinkNib,
  guhealth: LinkGu
};

export const PrivacyPolicyLink = withVariants(variants, LinkWithModal as any);
