import {useEffect} from 'react';
import {connect} from 'formik';
import scrollToErrors, {getDropinInstance, getFirstErrorElement, scrollToBraintreeError} from './scrollToErrors';

/*
function from here (but modified, call back is scrollToErrors)
https://github.com/jaredpalmer/formik/issues/1484
*/

function OnSubmitValidationErrorScrollTo(props: any) {
  const {formik, braintreeResponse, formikFieldsBelowBraintree} = props;
  const effect = () => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      const dropinInstance = getDropinInstance();
      const firstErrorItemElement = getFirstErrorElement();

      // scroll to BT if:
      // - There is a BT error and
      // - there is no formik errors or
      // - there are formik errors but they exist below the drop in ui in the dom
      if (braintreeResponse && braintreeResponse.error && dropinInstance) {
        // Convert formik fields array to the appropriate error array

        let formikErrorsBelowBraintree: string[] = [];

        if (formikFieldsBelowBraintree) {
          formikErrorsBelowBraintree = formikFieldsBelowBraintree.map((item) => {
            return `error-${item}`;
          });
        }

        if (!firstErrorItemElement || formikErrorsBelowBraintree.includes(firstErrorItemElement.id)) {
          scrollToBraintreeError(dropinInstance);
        } else {
          // bt error exists but the drop in ui is below > 1 formik errors
          scrollToErrors();
        }
      } else {
        // no bt  or bt error
        scrollToErrors();
      }
    }
  };
  useEffect(effect, [formik.submitCount, formik.isSubmitting]);

  return null;
}

export default connect(OnSubmitValidationErrorScrollTo) as any;
