import {useEffect, useState} from 'react';

//This hook can be used for two-pass rendering.
//- First render: Gatsby generates static HTML with `gatsby build`. - static content only
//- Second render: When React app is mounted on the client - dynamic content
// Read more - https://www.joshwcomeau.com/react/the-perils-of-rehydration/

export function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}
