import React from 'react';
import Copy from '@nib-components/copy';
import {PrivacyPolicyLink} from './privacyPolicyLink';

export const getBrandPrivacyMessage = (brand) => {
  switch (brand) {
    case 'apia':
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>By providing your contact details, you consent to the Apia Health Insurance team or our partners contacting you about this quote (in line with our{' '}
          <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
    case 'aami':
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>By providing your contact details, you consent to the AAMI Health Insurance team or our partners contacting you about this quote (in line with our{' '}
          <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
    case 'suncorp':
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>By providing your contact details, you consent to the Suncorp Health Insurance team or our partners contacting you about this quote (in line with our{' '}
          <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
    case 'real':
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>By providing your contact details, you consent to the Real Insurance team or our partners contacting you about this quote (in line with our{' '}
          <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
    case 'seniors':
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>By providing your contact details, you consent to the Australian Seniors Health Insurance team or our partners contacting you about this quote (in line with
          our <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
    case 'nib':
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>If you choose to provide us with your contact details, you consent to us or our partners contacting you about this quote (in line with our{' '}
          <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
    case 'priceline':
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>By providing your contact details, you consent to the Priceline Health Insurance team or our partners contacting you about this quote (in line with our{' '}
          <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
    case 'ing':
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>By providing your contact details, you consent to the ING Health Insurance team or our partners contacting you about this quote (in line with our{' '}
          <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
    case 'guhealth':
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>By providing your contact details, you consent to the GU Health team or our partners contacting you about this quote (in line with our{' '}
          <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
    default:
      return (
        <Copy small measure={false}>
          <b>We respect your privacy. </b>We collect and use your information to help you find the right cover (in line with our <PrivacyPolicyLink variant={brand} /> of course).
        </Copy>
      );
  }
};
