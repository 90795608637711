/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {withVariants} from '../../WithVariants';
import Link from '@nib-components/link';
import {useBrandSpecificLinksStatic} from '../../../hooks/UseBrandSpecificLinksStatic';

type ErrorLinksProps = {
  isImportant?: boolean;
};

const ContactLink = ({isImportant}: ErrorLinksProps) => {
  const brandSpecificLinks = useBrandSpecificLinksStatic();
  return (
    <Link component="a" href={brandSpecificLinks.contactUs}>
      contact us
    </Link>
  );
};

const CallLink = ({isImportant}: ErrorLinksProps) => {
  const brandSpecificLinks = useBrandSpecificLinksStatic();
  return (
    <>
      call <Link href={`tel:${brandSpecificLinks.contactNumber.replace(/\s/g, '')}`}>{brandSpecificLinks.contactNumber}</Link>
    </>
  );
};

const NibLinkOrCall = ({isImportant}: ErrorLinksProps) => {
  return isImportant ? <CallLink /> : <ContactLink />;
};

const variants = {
  nib: NibLinkOrCall,
  apia: CallLink
};

// We are always using phone number for white labels in error messages.
// But for nib it can be a phone number or link to contact us page. isImportant flag prop is used when we want to show phone number for nib.
export const ErrorLink = withVariants(variants, CallLink);
