import React, {Component as ReactComponent} from 'react';
import {connect} from 'react-redux';
import Component from './Component';
import _get from 'lodash/get';
import {FormikHelpers} from 'formik';
import {WelcomeDetailsValues, WelcomeDetailsFormOwnProps, WelcomeDetailsContainerProps} from './types';
import {ARHIPageList, ARHIPageIndex} from '../../../constants';
import {navigate} from 'gatsby';
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
import {isAxiosError} from '../../../redux/types';
import {BaseFormState} from '../../../types/common';
import validate from './validation';
import {preSaveMapper} from '../utils';
import {getCookieSessionId} from '../../../services/cookies';
import {scaleDefault} from '../../../templates/utils';
import {isNibBrand, stringToDate} from '../../../services/utils';
import {Funds, Scale} from '@nib/phi-constants';
import {getCurrentDate} from '../../../utils';

class Container extends ReactComponent<WelcomeDetailsContainerProps, BaseFormState> {
  updateSession = async (values: WelcomeDetailsValues, isPageComplete?: boolean) => {
    const sessionId = getCookieSessionId();
    if (!sessionId) {
      const session = await this.props.initSession({}, true);
      if (!session || isAxiosError(session)) {
        return session;
      }
    }
    return this.props.saveWelcomeDetailsForm(values, isPageComplete, this.props.queryParams?.state, this.props.queryParams?.excess);
  };

  handleAutosave = (values: WelcomeDetailsValues, touched: object, fieldName) => {
    const errors = validate()(values);
    const sessionId = getCookieSessionId();
    // Do not save unless user already has a quote (via having submitted the form previously).
    if (!sessionId) {
      return;
    }
    const changedFieldHasError = fieldName && _get(errors, fieldName);
    const fieldHasNotChanged = _get(values, fieldName) === _get(this.props, fieldName);
    if (changedFieldHasError || fieldHasNotChanged) {
      return;
    }
    const safeValues = preSaveMapper(values, errors, touched, this.props);
    this.updateSession(safeValues);
  };

  handleSubmit = (values: WelcomeDetailsValues, formikHelpers: FormikHelpers<WelcomeDetailsValues>) => {
    const submit = async () => {
      const response = await this.updateSession(values, true);

      if (!response || isAxiosError(response)) {
        formikHelpers.setSubmitting(false);
      }
      // Navigate anyway as we want to let the user navigate to Hospital products regardless
      this.props.trackJoinHealthFunnelWelcomeSubmit(this.props.tealiumBusinessSegment);
      await navigate(ARHIPageList[ARHIPageIndex.HOSPITAL].link);
    };

    submit();
  };

  render() {
    const scaleValue = this.props.queryParams && this.props.queryParams.scale ? Scale[`${this.props.queryParams.scale}`] : this.props.scale || scaleDefault;
    const residentTypeValue = this.props.queryParams?.residentType || Funds.ARHI;

    const welcomeDetailsFormProps: WelcomeDetailsFormOwnProps = {
      handleSubmit: this.handleSubmit,
      updateSession: this.handleAutosave,
      initialValues: {
        dateOfBirth: this.props.dateOfBirth || '',
        dateOfBirthDay: this.props.dateOfBirthDay || '',
        dateOfBirthMonth: this.props.dateOfBirthMonth || '',
        dateOfBirthYear: this.props.dateOfBirthYear || '',
        firstName: this.props.firstName || '',
        partnerDateOfBirth: this.props.partnerDateOfBirth || '',
        partnerDateOfBirthDay: this.props.partnerDateOfBirthDay || '',
        partnerDateOfBirthMonth: this.props.partnerDateOfBirthMonth || '',
        partnerDateOfBirthYear: this.props.partnerDateOfBirthYear || '',
        phoneNumber: this.props.phoneNumber || '',
        email: this.props.email || '',
        scale: scaleValue,
        residentType: residentTypeValue,
        visaSubclass: '',
        contact: this.props.contact || true
      },
      queryParams: this.props.queryParams,
      showArhiPPCInformation: this.props.showArhiPPCInformation,
      referAFriendMembershipNumber: this.props.referAFriendMembershipNumber
    };

    // We only want to add this to the form if we are in this pre ppc period
    // because if we had it on all the time, we would be overriding the session-api CSD default
    if (this.props.showArhiPPCInformation) {
      const coverStartDateValue = (this.props.coverStartDate && stringToDate(this.props.coverStartDate as string)) || getCurrentDate();
      welcomeDetailsFormProps.initialValues.coverStartDate = coverStartDateValue;
    }

    if (isNibBrand()) {
      welcomeDetailsFormProps.initialValues.visitorType = this.props.visitorType || '';
    }

    return <Component {...welcomeDetailsFormProps} />;
  }
}

const enhancedForm = connect(mapStateToProps, mapDispatchToProps);

export default enhancedForm(Container as any);
