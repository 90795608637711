import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {trackQuoteHealthUdo} from '../../redux/metrics/thunks';
import {getFetchedSession} from '../../redux/session/selectors';
import {getSessionCookie} from '../../services/cookies';
import {ARHIFunnelPage} from '../../types/pages';
import {useTealiumBusinessSegmentStatic} from '../UseTealiumBusinessSegment';

export const useTealiumPageTrack = (page: ARHIFunnelPage, hasPageAccess: boolean) => {
  const fetchedSession = useSelector(getFetchedSession);
  const sessionCookie = getSessionCookie();
  const tealiumBusinessSegment = useTealiumBusinessSegmentStatic();
  const dispatch = useDispatch();

  // We want to only call the page track once, but we can't rely on the dependency array in useEffect
  // So let useEffect determine when it should run, and this state variable determine
  // if we need to do anything
  const hasTrackedPage = useRef(false);

  // @TODO come back and refactor this into something more elegant.
  // This might change after PHISL-3215 changes are in master
  useEffect(() => {
    if (!hasTrackedPage.current && hasPageAccess) {
      if (!sessionCookie) {
        dispatch(trackQuoteHealthUdo(page, tealiumBusinessSegment));
        hasTrackedPage.current = true;
      } else if (fetchedSession && sessionCookie) {
        dispatch(trackQuoteHealthUdo(page, tealiumBusinessSegment));
        hasTrackedPage.current = true;
      }
    }
  }, [sessionCookie, fetchedSession, hasPageAccess, page, dispatch, tealiumBusinessSegment]);
};
