import {captureMessage} from '@sentry/gatsby';
import {scrollToHTMLElement} from '../../services/utils';

// have to be careful as the name / id can be totally dynamic.
function isDateOfBirthField(element) {
  // If it's a dob field the element passed in will actually be a wrapping div, so check for a day field.
  return element.querySelector('input[data-fieldtype="day"]');
}

function getElementToFocusOn(element: HTMLElement) {
  let fieldElement: HTMLElement | null = null;
  let dateOfBirthField: HTMLElement | null = null;
  let fieldName = '';

  // below gets 'anithing--here' from 'blah-blah-anything--here'
  // and 'yolo' from 'blah-blah-yolo' etc

  const textArray = element.id.split('-');
  if (textArray.length) {
    // slice(2) in case of error-error-email field, but in fixed form controls it's error-email etc.
    fieldName = textArray.slice(1).join('-').trim();
  }
  // above is to replace the below, turns out FF doesn't support lookaheads, leaving it here, could use in future.
  // const regExGetAfterSecondHyphen = /(?<=([^-]*-){2}).*/;
  // const fieldName: string | null = regExGetAfterSecondHyphen.exec(errorId)![0];

  let elementMatchingId: HTMLElement | null = document.getElementById(fieldName);
  const elementMatchingName: HTMLElement | null = document.getElementsByName(fieldName)[0];

  if (elementMatchingId) {
    dateOfBirthField = isDateOfBirthField(elementMatchingId);
    // if DOB field is what has errored focus on the first day field
    if (dateOfBirthField) {
      elementMatchingId = dateOfBirthField;
    }
  }

  /*
    if we find a field with matching `name` prop we'll give that precedence over ID... why?
    because we have a field named title... but we have a <title> tag with an id of title...
    (╯°□°)╯︵ ┻━┻
  */
  if (elementMatchingName && !dateOfBirthField) {
    fieldElement = elementMatchingName;
  } else if (elementMatchingId) {
    fieldElement = elementMatchingId;
  }

  return fieldElement;
}

function scroll(element) {
  scrollToHTMLElement(element);

  try {
    const formField: any = getElementToFocusOn(element);
    if (formField) {
      // We scroll to the element above so avoid extra conflicting scroll built into .focus
      formField.focus({preventScroll: true});
    }
  } catch (error) {
    console.error('user scrolled but no field was given focus', error);
    captureMessage(`user scrolled but no field was given focus -- error: ${error}`, 'error');
  }
}

export const scrollToBraintreeError = (element) => {
  if (element) {
    element.scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    });
  }
};

/**
 * We use the specific selector on the data-mesh-component attribute, then drill into it's direct child, which is the error-* element we had before.
 * @returns
 */
function getErrorElements() {
  return [].slice.call(document.querySelectorAll('div[data-mesh-component^="FORM-CONTROL--ERROR-MESSAGE"] > div'));
}

export const getFirstErrorElement = () => {
  const errorsElementList: any[] = getErrorElements();

  let childErrorElement;

  if (errorsElementList.length) {
    childErrorElement = errorsElementList[0];
  }
  return childErrorElement;
};

const scrollToErrors = () => {
  const childErrorElement = getFirstErrorElement();
  if (childErrorElement) {
    scroll(childErrorElement);
  }
};

export const getDropinInstance = () => {
  const elementList: any = document.querySelectorAll('[id^="braintree--dropin__"]');

  let firstElement;

  if (elementList) {
    firstElement = elementList[0];
  }

  return firstElement;
};

const scrollToBraintreeInstance = () => {
  const dropinInstance = getDropinInstance();
  if (dropinInstance) {
    scrollToBraintreeError(dropinInstance);
  }
};

export {scrollToErrors as default, scrollToBraintreeInstance};
