import React from 'react';
import {HelloGraphicIcon} from '@nib/icons';
import {Box, Columns, Column} from '@nib/layout';
import {getBrandPrivacyMessage} from './privacyMessageVariants';
import {getBrand} from '../../services/utils';

const PrivacyMessage = () => {
  return (
    <>
      <Columns>
        <Column width="content">
          <Box marginTop={1} marginRight={4}>
            <HelloGraphicIcon size="md" />
          </Box>
        </Column>
        <Column width="2/3">{getBrandPrivacyMessage(getBrand())}</Column>
      </Columns>
    </>
  );
};

export default PrivacyMessage;
