import {useStaticQuery, graphql} from 'gatsby';

export type WelcomeFieldLabels = {
  firstName: string;
  dateOfBirth: string;
  partnerDateOfBirth: string;
  phoneNumber: string;
  scale: string;
  residentType: string;
  visaSubclass: string;
};

type WelcomeStaticQuery = {
  brandContent: {
    welcomeContent: {
      formTitle: string;
      fieldLabels: WelcomeFieldLabels;
      rewardsInfoBox: {
        headingText: string;
        bodyText: string;
      };
    };
  };
};

export const useWelcomeStatic = () => {
  const {brandContent} = useStaticQuery<WelcomeStaticQuery>(
    graphql`
      query WelcomeStaticQuery {
        brandContent {
          welcomeContent {
            formTitle
            fieldLabels {
              firstName
              dateOfBirth
              partnerDateOfBirth
              phoneNumber
              scale
              residentType
              visaSubclass
            }
            rewardsInfoBox {
              headingText
              bodyText
            }
          }
        }
      }
    `
  );
  return brandContent.welcomeContent;
};
