import React from 'react';
import {Box} from '@nib/layout';
import MeshAlert from '@nib-components/alert';
import {ARHIPageIndex, invalidResponseMessage} from '../../../constants';
import {ARHIFunnelPage} from '../../../types/pages';
import {getBrand} from '../../../services/utils';
import {ErrorLink} from '../../Links/ErrorLink';

type ErrorAlertProps = {
  currentPage?: ARHIFunnelPage;
  title?: string;
};

const ErrorAlert = ({currentPage, title}: ErrorAlertProps): JSX.Element => {
  const funnel = currentPage ? (currentPage.stepOrder < ARHIPageIndex.PERSONAL_DETAILS ? 'quote' : 'join') : 'quote';

  return (
    <Box marginVertical={4}>
      <MeshAlert fullWidth title={title ? title : invalidResponseMessage} type="error">
        Please try again or <ErrorLink variant={getBrand() as any} /> to complete your {funnel}.
      </MeshAlert>
    </Box>
  );
};

export default ErrorAlert;
