import React from 'react';
import {Field} from 'formik';
import {Props, withVariants} from '../../../WithVariants';
import {FIELD_NAMES, RESIDENT_TYPES, visaSubClasses} from '../constants';
import {YesNoRadio as ResidentTypeRadio, VisaSubclass} from '@nib/form-fields';
import {ResidentTypeGroupProps} from './types';
import {PrimaryButton} from '@nib-components/button';
import {Box, Stack} from '@nib/layout';
import {ChevronRightSystemIcon} from '@nib/icons';
import {Funds} from '@nib/phi-constants';

const baseWorkersUrl = '/overseas-working-visitors';
const baseStudentsUrl = '/overseas-students';

const getWorkersLink = (visaSubclass?: string): string => {
  // no selection -> go to overseas-working-visitors
  // if selection, go to [mapped route] or the default workers route if no mapping found
  const overseasWorkersURLs = {
    '482': `${baseWorkersUrl}/482-temporary-skills-shortage-health-insurance`,
    '485': `${baseWorkersUrl}/485-temporary-graduate-visa-health-insurance`,
    '457': `${baseWorkersUrl}/457-visa-health-insurance`,
    '500': baseStudentsUrl
  };
  const url = visaSubclass ? overseasWorkersURLs[visaSubclass] : baseWorkersUrl;
  return url || baseWorkersUrl;
};

const NibResidentTypeGroup = (props: ResidentTypeGroupProps) => {
  return (
    <>
      <Field
        name={props.residentTypeName || FIELD_NAMES.RESIDENT_TYPE}
        id={props.residentTypeId || FIELD_NAMES.RESIDENT_TYPE}
        label={props.residentTypeLabel}
        component={ResidentTypeRadio}
        options={RESIDENT_TYPES}
      />

      {props.values.residentType === Funds.IWHI && (
        <Stack space={7}>
          <div id="old-visa-selector">
            <Field
              name={props.visaSubclassName || FIELD_NAMES.VISA_SUBCLASS}
              id={props.visaSubclassId || FIELD_NAMES.VISA_SUBCLASS}
              label={props.visaSubclassLabel}
              component={VisaSubclass}
              options={visaSubClasses}
              suggestions={visaSubClasses}
              placeholder="Select your visa"
            />
          </div>
          <PrimaryButton href={`${getWorkersLink(props.values.visaSubclass)}`} fullWidth={false} icon={ChevronRightSystemIcon}>
            Start
          </PrimaryButton>
        </Stack>
      )}

      {props.values.residentType === Funds.ISHI && (
        <Box>
          <PrimaryButton href={baseStudentsUrl} fullWidth={false} icon={ChevronRightSystemIcon}>
            Start
          </PrimaryButton>
        </Box>
      )}
    </>
  );
};

const variants = {
  nib: NibResidentTypeGroup
};

// For White label partners, we don't want this component at all. But this is only temporary.
// With this approach, we can default all brands except for nib to return no render
// And add the appropriate brand implementation later on with little impact
export const ResidentTypeGroup = withVariants(variants, undefined);
export type ResidentTypeGroup = Props<typeof variants>;
