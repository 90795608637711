import {sanitizeErrors, validFirstName, validPhone} from '@nib/validation';
import {WelcomeDetailsValues} from './types';
import validateDateOfBirthFields from '../sharedCustomValidation';
import {FUNNEL_NAME} from '../../../services/utils';
import {Scale} from '@nib/phi-constants';

const validate =
  (funnel = FUNNEL_NAME) =>
  (values: WelcomeDetailsValues) => {
    let errors: any = {};

    if (values.firstName?.length) {
      errors.firstName = validFirstName(values.firstName, funnel);
    }
    if (values.phoneNumber?.length) {
      errors.phoneNumber = validPhone(values.phoneNumber, funnel, 'australian');
    }
    if (values.dateOfBirthDay?.length || values.dateOfBirthMonth?.length || values.dateOfBirthYear?.length) {
      errors = validateDateOfBirthFields(values, errors, 'dateOfBirthYear', 'dateOfBirthMonth', 'dateOfBirthDay', 'dateOfBirth', 99, 16, funnel);
    }
    if ([Scale.Family, Scale.Couple].includes(values.scale)) {
      // only validate if the user has entered a value in a date field
      if (values.partnerDateOfBirthDay?.length || values.partnerDateOfBirthMonth?.length || values.partnerDateOfBirthYear?.length) {
        errors = validateDateOfBirthFields(values, errors, 'partnerDateOfBirthYear', 'partnerDateOfBirthMonth', 'partnerDateOfBirthDay', 'partnerDateOfBirth', 99, 16, funnel);
      }
    }

    const errorResult = sanitizeErrors(errors);
    return errorResult;
  };
export default validate;
