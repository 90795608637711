import {FormikProps} from 'formik';
import {WelcomeDetailsValues} from './types';
import {getItemsFromDate} from '../../../services/utils';
import {FIELD_NAMES} from './constants';

export const saveFormOnBlur = (formikBag: FormikProps<WelcomeDetailsValues>, updateFunc: UpdateFunc, optionalFieldName?) => (event) => {
  // 'optionalFieldName' accommodates for optional field name or when onBlur does not pass in event with a target name.
  if (optionalFieldName || (event && event.target && event.target.name)) {
    const fieldName = optionalFieldName || (event && event.target && event.target.name);
    updateFunc(formikBag.values, formikBag.touched, fieldName);
  }
};

/* Radio buttons / text input fields / selects
 *  Any field / component that passes up a event object when calling the onChange function
 */
export const saveFormOnChange = (formikBag: FormikProps<WelcomeDetailsValues>, updateFunc: UpdateFunc) => (event) => {
  if (event && event.target && event.target.name) {
    const value = event.target.value;
    const updatedValue = {
      [event.target.name]: value
    };
    updateFunc({...formikBag.values, ...updatedValue}, formikBag.touched, event.target.name);
  }
};

export const saveFormOnDOBChange =
  (formikBag: FormikProps<WelcomeDetailsValues>, updateFunc: UpdateFunc, fieldName: string = FIELD_NAMES.DATE_OF_BIRTH) =>
  (e, newValue) => {
    // DOB event handlers send latest Date but not the last change from input, e.g. '1999' for year is sent '199'. Unlikely, dependants' DOB doesn't have this issue.
    const newDateOfBirth = {
      [fieldName]: newValue,
      [`${fieldName}Day`]: getItemsFromDate(newValue, 'day'),
      [`${fieldName}Month`]: getItemsFromDate(newValue, 'month'),
      [`${fieldName}Year`]: getItemsFromDate(newValue, 'year')
    };
    updateFunc({...formikBag.values, ...newDateOfBirth}, formikBag.touched, fieldName);
  };

/* Date fields */
export const saveFormOnDateChange = (formikBag: FormikProps<WelcomeDetailsValues>, updateFunc: UpdateFunc, dateFieldName: string) => (date) => {
  updateFunc({...formikBag.values, [dateFieldName]: date}, formikBag.touched, dateFieldName);
};

type UpdateFunc = (values: WelcomeDetailsValues, touched: object, fieldName: string) => void;
