import type {PolicyHolder, Nullable} from '@nib/types-session-api';

import {getAllowLeadsContact, getVisitorType} from './../session/selectors/aboutYouDetails';
import {createSelector} from 'reselect';
import {getPolicyHolder, getScale, getPartnerDob, getPolicyHolderEmail} from '../session/selectors/personalDetails';
import {getPhoneNumber} from '../session/selectors/contactDetails';
import {Scale} from '@nib/phi-constants';
import {ARHIJoinState} from '../rootReducer';
import {WelcomeDetailsValues} from '../../components/Forms/Welcome/types';
import {getItemsFromDate, dateTimeStringToDateString, ensureDateNotInPast} from '../../services/utils';
import {getCoverStartDate} from '../session/selectors/financialDetails';

export const getWelcomeDetailsValues = createSelector<
  ARHIJoinState,
  PolicyHolder,
  Nullable<string>,
  Nullable<string>,
  Nullable<string>,
  Nullable<string>,
  Nullable<string>,
  Nullable<string>,
  Nullable<boolean>,
  WelcomeDetailsValues
>(
  getPolicyHolder,
  getPhoneNumber,
  getPolicyHolderEmail,
  getScale,
  getPartnerDob,
  getCoverStartDate,
  getVisitorType,
  getAllowLeadsContact,
  (policyHolder, phoneNumber, email, scale, partnerDob, coverStartDate, visitorType, allowLeadsContact) => ({
    dateOfBirth: policyHolder.dateOfBirth ? dateTimeStringToDateString(policyHolder.dateOfBirth) : null,
    dateOfBirthDay: getItemsFromDate(policyHolder.dateOfBirth, 'day'),
    dateOfBirthMonth: getItemsFromDate(policyHolder.dateOfBirth, 'month'),
    dateOfBirthYear: getItemsFromDate(policyHolder.dateOfBirth, 'year'),
    partnerDateOfBirth: partnerDob ? dateTimeStringToDateString(partnerDob) : null,
    partnerDateOfBirthDay: getItemsFromDate(partnerDob, 'day'),
    partnerDateOfBirthMonth: getItemsFromDate(partnerDob, 'month'),
    partnerDateOfBirthYear: getItemsFromDate(partnerDob, 'year'),
    firstName: policyHolder.firstName,
    phoneNumber: phoneNumber,
    email,
    scale: Scale[scale || ''],
    coverStartDate: ensureDateNotInPast(coverStartDate),
    visitorType: visitorType,
    contact: allowLeadsContact
  })
);
