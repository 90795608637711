import {getWelcomeDetailsValues} from '../../../redux/welcomeDetails/selectors';
import {ARHIJoinState} from '../../../redux/rootReducer';
import {WelcomeDetailsContainerStateProps} from './types';
import {selectShowArhiPPCInformation} from '../../../redux/featureToggles/selectors';
import {getReferAFriendMembershipNumber} from '../../../redux/session/selectors';
import {getNonExtendedScale} from '../../../utils';
import {getHasPartner, getScale} from '../../../redux/session/selectors/personalDetails';

export default (state: ARHIJoinState): WelcomeDetailsContainerStateProps => {
  const formValues = getWelcomeDetailsValues(state);
  return {
    ...formValues,
    showArhiPPCInformation: Boolean(selectShowArhiPPCInformation(state)),
    referAFriendMembershipNumber: getReferAFriendMembershipNumber(state),
    scale: getNonExtendedScale(getScale(state), getHasPartner(state))
  };
};
